html {
  scroll-behavior: smooth;
}

*, body, h1, h2, h3, p, ul, ol, li {
  margin: 0;
  padding: 0;
}

a {
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.bando {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: initial;
  text-align: center;
  color: #fff;
  background-color: #000;
  padding: 3px;
}

.blinking {
  font-weight: bold;
  animation: 1s infinite blink;
}

.infinite-strip .blinking {
  color: tomato;
}

.blinking-clock {
  animation: 1s infinite blink;
}

.infinite-strip {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.text-container {
  white-space: nowrap;
  animation: 30s linear infinite scrollText;
}

@media (width <= 768px) {
  .text-container {
    animation: 8s linear infinite scrollText;
  }
}

.texte {
  margin: 0 20px;
  font-size: 20px;
  display: inline-block;
}

.pit {
  animation: 7.5s linear infinite rotate;
  display: inline-block;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(-360deg);
  }
}

@keyframes scrollText {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

body {
  box-sizing: border-box;
  color: #000;
  cursor: default;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: helvetica;
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none;
}

section.test {
  flex-direction: row;
  margin: 10px 10px 0 15px;
  display: flex;
}

.arrow-container {
  text-align: center;
  animation: 1.5s infinite bounce;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }
}

svg {
  fill: #000;
  width: 50px;
  height: 50px;
}

.header-nav {
  z-index: 1000;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  margin: 40px 15px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.lepotoriko {
  padding-top: 1px;
  font-size: 24px;
  font-weight: normal;
  position: absolute;
}

.lepotoriko:after {
  content: "";
  transform-origin: 100% 100%;
  background-color: tomato;
  width: 100%;
  height: 3px;
  transition: transform .3s;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
}

.lepotoriko:hover:after {
  transform-origin: 0 100%;
  transform: scaleX(1);
}

nav {
  gap: 10px;
  margin-top: 5px;
  display: flex;
  position: absolute;
  right: 0;
}

nav a {
  color: #000;
  z-index: 1;
  letter-spacing: -1px;
  mix-blend-mode: difference;
  margin: 0 5px;
  font-size: 18px;
  font-weight: lighter;
  text-decoration: none;
  transition: color .3s;
  position: relative;
}

@media (width <= 768px) {
  .lepotoriko:before {
    content: "Lepotoriko";
    font-size: 15px;
    font-weight: normal;
  }

  .lepotoriko {
    content: "";
    font-size: 20px;
  }

  nav a {
    font-size: 16px;
  }
}

@media (width >= 768px) {
  .lepotoriko:before {
    content: "Lepotoriko";
    letter-spacing: -1px;
    font-weight: lighter;
  }

  .lepotoriko {
    font-size: 24px;
  }
}

#loading-screen {
  text-align: center;
  z-index: 9999;
  background-color: #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: opacity .8s, visibility .8s;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.loading-text {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 20px;
  font-size: 20px;
  font-weight: normal;
}

.loading-percentage {
  font-size: 2rem;
  font-weight: normal;
  overflow: hidden;
}

.progress-bar {
  background-color: #191919;
  border-radius: 10px;
  width: 300px;
  height: 5px;
  overflow: hidden;
}

.progress-fill {
  background-color: #fff;
  border-radius: 2px;
  width: 0%;
  height: 100%;
  transition: width 1.2s cubic-bezier(.3, .7, .5, 1);
}

#main-content {
  text-align: center;
  padding: 50px;
  display: none;
}

.background {
  background: #fff;
}

.hero {
  text-align: center;
  color: #fff;
  background: url("../img/PP.48088bc0.webp") center / cover no-repeat;
  justify-content: center;
  align-items: center;
  height: 70vh;
  margin-top: 25px;
  display: flex;
  position: relative;
}

.hero:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.hero-content {
  z-index: 2;
  padding: 20px;
  position: relative;
}

.hero-content h1, .hero-content p {
  margin-bottom: 20px;
  font-size: 30px;
}

.hero-text {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: -1px;
  margin-top: 350px;
  font-size: 12px;
  font-weight: lighter;
}

.blog-all {
  margin-top: 45px;
}

.blog {
  margin: 70px 15px 50px;
}

.container_02 {
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: 0;
  padding: 0;
  display: flex;
}

.blog-post {
  background-color: #fff;
  flex-direction: row;
  width: calc(100% - 40px);
  margin: 30px 0 0;
  transition: all .2s;
  display: flex;
}

.blog-post:hover {
  transform: translateY(-3px);
}

.image-container {
  width: 50%;
  overflow: hidden;
}

.grid-container_02 {
  grid-gap: 10px;
  grid-template-columns: repeat(1, 1fr);
  display: grid;
}

.grid-video, .grid-image {
  object-fit: cover;
  width: 100%;
  height: 400px;
}

.content-blog {
  box-sizing: border-box;
  flex: 1;
  margin-left: 30px;
}

.post-title {
  letter-spacing: -25px;
  text-transform: uppercase;
  margin-top: -40px;
  font-family: montserrat;
  font-size: 13vw;
  font-style: italic;
  font-weight: bold;
  display: inline-block;
  position: relative;
}

.post-title:after {
  content: "";
  background-color: tomato;
  width: 0;
  height: 7px;
  transition: width .3s ease-in-out;
  position: absolute;
  bottom: 30px;
  left: 10px;
}

.post-title:hover:after {
  width: 100%;
}

.down-post-title {
  color: #fff;
  background: tomato;
  border-radius: 30px;
  margin: -10px 0 10px;
  padding: 5px 13px 5px 10px;
  font-style: italic;
  font-weight: lighter;
  position: absolute;
}

.date {
  color: #888;
  margin: 40px 0 0;
  font-style: italic;
}

.post-content {
  letter-spacing: -1px;
  margin: 10px 0 0;
  font-size: 27px;
  font-style: italic;
  font-weight: lighter;
}

.more {
  z-index: 1000;
  text-decoration: underline;
  transition: all .2s;
  position: absolute;
  bottom: 0;
  right: 0;
}

.more:hover {
  color: red;
  transition: all .2s;
}

@media screen and (width >= 2560px) {
  .grid-image {
    height: 560px;
  }
}

@media screen and (width <= 425px) {
  .blog-post {
    flex-direction: column;
    width: 100%;
  }

  .post-title {
    letter-spacing: -15px;
    margin: -20px 0 0;
    font-size: 25vw;
    font-style: normal;
  }

  .date {
    color: #888;
    margin: 35px 0 0;
    font-style: italic;
  }

  .down-post-title {
    margin: -5px 0 10px;
  }

  .image-container, .content {
    width: 100%;
    height: auto;
  }

  .grid-container_02 {
    grid-gap: 10px;
    grid-template-columns: repeat(1, 1fr);
    display: grid;
  }

  .content-blog {
    margin-left: 0;
    padding-top: 20px;
  }
}

footer {
  text-align: center;
  color: #fff;
  letter-spacing: -1px;
  background-color: #000;
  padding: 30px 20px;
}

.footer-title {
  color: #777;
  margin: 0;
  font-size: 100px;
  font-weight: bold;
}

.footer-info {
  margin-top: 10px;
  font-size: 17px;
  font-weight: 100;
  line-height: 1.3;
}

.footer-info a {
  color: #fff;
  text-decoration: none;
}

.footer-info a:hover {
  text-decoration: underline;
}

.subscribe {
  text-transform: uppercase;
  margin-top: 30px;
  font-size: 16px;
}

.subscribe a {
  color: #fff;
  text-decoration: none;
}

.subscribe a:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

.copyright {
  color: gray;
  letter-spacing: normal;
  font-size: 10px;
}

.image-container-img {
  max-width: auto;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  padding-top: 50px;
  display: grid;
}

.image-box img {
  object-fit: cover;
  width: 100%;
  height: auto;
  display: block;
}

@media (width <= 768px) {
  .image-container-img {
    grid-template-columns: repeat(2, 1fr);
  }
}

.margin-top {
  margin-top: 100px;
}

.container {
  max-width: 100%;
  margin: auto;
}

.row {
  flex-wrap: wrap;
  display: flex;
}

.col-md-6 {
  box-sizing: border-box;
  flex: calc(50% - 10px);
}

a {
  color: inherit;
  text-decoration: none;
}

.content-column {
  transition: transform .1s;
  position: relative;
  overflow: hidden;
}

.content-column:hover {
  transform: scale(1);
}

.blue {
  position: relative;
  overflow: hidden;
}

.conteneur {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}

.image {
  object-fit: cover;
  opacity: 1;
  z-index: 1;
  width: 100%;
  height: 100%;
  transition: opacity .2s, z-index 0s .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.video {
  object-fit: cover;
  opacity: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  transition: opacity .2s, z-index 0s .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.click {
  text-transform: uppercase;
  letter-spacing: -1px;
  margin: 200px 0 20px 15px;
  font-size: 12px;
  font-weight: lighter;
}

@media (width <= 768px) {
  .col-md-6 {
    flex: 100%;
  }
}

.container-02 {
  background-color: #fff;
  border-radius: 8px;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: 0 15px;
  display: grid;
}

.title-column {
  text-align: left;
}

.big-title {
  letter-spacing: -5px;
  text-transform: uppercase;
  font-family: montserrat;
  font-size: 40px;
  font-style: italic;
  font-weight: bold;
  display: inline-block;
}

.details-column {
  text-align: left;
}

.detail-item {
  color: #666;
  margin: 5px 0;
  font-size: .9rem;
}

.detail-partnership {
  color: #fff;
  background: tomato;
  border-radius: 30px;
  margin: 0 0 0 -10px;
  padding: 5px 13px 5px 10px;
  font-style: italic;
  font-weight: lighter;
  position: absolute;
}

.watch-now-button {
  color: #007bff;
  margin-top: 10px;
  font-size: .9rem;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
}

.watch-now-button:hover {
  text-decoration: underline;
}

.content-column {
  text-align: left;
}

.content-paragraph {
  margin-bottom: 15px;
  font-size: 1rem;
  line-height: 1.6;
}

.content-link {
  color: #007bff;
  text-decoration: none;
}

.content-link:hover {
  text-decoration: underline;
}

@media (width <= 768px) {
  .container-02 {
    grid-template-columns: 1fr;
  }

  .text-content-column {
    margin-top: 40px;
  }

  .title {
    font-size: 1.5rem;
  }

  .content-paragraph {
    font-size: .9rem;
  }

  .selection-container {
    flex-direction: column;
    margin: 0;
  }

  .video-section, .images-section {
    flex: unset;
    width: 100%;
  }

  .images-section img {
    height: auto;
  }
}

.selection-container {
  max-width: auto;
  height: auto;
  margin-top: 50px;
  display: flex;
}

.video-section {
  aspect-ratio: 16 / 9;
  flex: 2;
  display: flex;
}

.video-section video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.images-section {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.images-section img {
  object-fit: cover;
  flex: 1;
  width: 100%;
  height: 50%;
}

.video-container {
  max-width: auto;
  width: 100%;
  display: inline-block;
  position: relative;
}

.video-element {
  width: 100%;
  height: auto;
}

.play-button {
  color: #fff;
  cursor: pointer;
  z-index: 10;
  background-color: #000000b3;
  border: none;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  font-size: 2rem;
  transition: background-color .3s;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.play-button:hover {
  background-color: #000000e6;
}

.play-button.hidden {
  display: none;
}

@media (width <= 768px) {
  .play-button {
    display: none;
  }
}

.contact-info {
  color: #000;
  letter-spacing: -1px;
  margin: 90px 15px 0;
  font-size: 20px;
  font-weight: 100;
}

.small-title {
  color: #464646;
  text-transform: uppercase;
  letter-spacing: -1px;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
}

.small-info, .info-a {
  letter-spacing: -1px;
  margin-bottom: 30px;
}

.info-info {
  text-transform: normal;
  letter-spacing: -1px;
}

.info-a:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

.info-copyright {
  color: gray;
  font-size: 10px;
  position: absolute;
  bottom: 10px;
}

/*# sourceMappingURL=index.bc916016.css.map */
