/**** © LEPOTORIKO ****/

/* ====================================
// DÉPART
// ==================================== */

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
}

body, h1, h2, h3, p, ul, ol, li {
  margin: 0;
  padding: 0;
}

a {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* ====================================
// BANDO NOIR
// ==================================== */

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.bando {
  background-color: black;
  font-size: 20px;
  padding: 3px;
  text-transform: uppercase;
  font-weight: initial;
  text-align: center;
  color: white;
}

.blinking {
  animation: blink 1s infinite; /* Animation de clignotement */
  font-weight: bold;
}

.infinite-strip .blinking {
  color: tomato;
}

.blinking-clock {
  animation: blink 1s infinite; /* Animation de clignotement */
}

/* ====================================
// BANDE INFINIE
// ==================================== */

.infinite-strip {
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
}

.text-container {
  white-space: nowrap;
  animation: scrollText 30s linear infinite;
}

@media (max-width: 768px) {
  .text-container {
    animation: scrollText 8s linear infinite;
  }
}

.texte {
  display: inline-block;
  margin: 0 20px;
  font-size: 20px;
}

.pit {
  display: inline-block;
  animation: rotate 7.5s linear infinite; /* Ajuste la durée et l'effet de rotation */
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

@keyframes scrollText {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
}}

/* ====================================
// LEPOTORIKO
// ==================================== */



/* ====================================
// BODY
// ==================================== */

body {
  margin: 0;
  padding: 0;
  font-family: 'helvetica';
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: black;
  cursor: default;
  overflow-y: scroll; /* Conserver le scroll */
}

body::-webkit-scrollbar {
  display: none; /* Masquer la barre de défilement */
}

/* ====================================
// SECTION HEADER
// ==================================== */

section.test {
  display: flex;
  flex-direction: row;
  margin: 10px 10px 0 15px;
}

/* ====================================
// HEURE HEADER
// ==================================== */



/* ====================================
// MUSIQUE
// ==================================== */

/* ====================================
// ARROW
// ==================================== */

.arrow-container {
  animation: bounce 1.5s infinite;
  text-align: center;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
}

svg {
  width: 50px;
  height: 50px;
  fill: black;
}

/* ====================================
// MENU
// ==================================== */

.header-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  justify-content: space-between;
  align-items: center;
  background-color: #fff; /* Pour éviter que le contenu en dessous ne se voie */
  margin: 40px 15px 0 15px;
}

.lepotoriko {
  font-size: 24px;
  padding-top: 1px;
  position: absolute;
  font-weight: normal;
}

.lepotoriko::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: tomato;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.lepotoriko:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

nav {
  position: absolute;
  display: flex;
  gap: 10px;
  margin-top: 5px;
  right: 0;
}

nav a {
  text-decoration: none;
  font-weight: normal;
  font-size: 18px;
  font-weight: lighter;
  color: black;
  font-size: 18px;
  margin: 0 5px;
  position: relative;
  z-index: 1;
  letter-spacing: -1px;
  mix-blend-mode: difference; /* Effet de fusion */
  transition: color 0.3s ease;
}

/* Mobile styles */
@media (max-width: 768px) {
  .lepotoriko::before {
    font-size: 15px;
    content: "Lepotoriko";
    font-weight: normal;
  }

  .lepotoriko {
    font-size: 20px;
    content: "";
  }

  nav a {
    font-size: 16px;
  }
}

/* Desktop styles */
@media (min-width: 768px) {
  .lepotoriko::before {
    content: "Lepotoriko";
    font-weight: lighter;
    letter-spacing: -1px;
  }

  .lepotoriko {
    font-size: 24px;
  }
}

/* ====================================
// loading
// ====================================

/* Écran de chargement */
#loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: black;
  z-index: 9999;
  transition: opacity 0.8s ease, visibility 0.8s ease;
}

.loading-text {
  font-size: 20px;
  color: white;
  text-transform: uppercase;
  letter-spacing: 20px;
  font-weight: normal;
}

.loading-percentage {
  font-size: 2rem;
  font-weight: normal;
  overflow: hidden;
}

/* Barre de progression */
.progress-bar {
  width: 300px;
  height: 5px;
  background-color: #191919;
  border-radius: 10px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  width: 0%;
  background-color: white;
  border-radius: 2px;
  transition: width 1.2s cubic-bezier(0.3, 0.7, 0.5, 1);

}

/* Contenu principal */
#main-content {
  display: none;
  text-align: center;
  padding: 50px;
}

/* ====================================
// MIX-BLEND-MOD
// ==================================== */

.background {
  background: white;

}

/* ====================================
// VIDEO
// ==================================== */

/* Section héros */
.hero {
  background: url(/src/img/PP.webp) no-repeat center center/cover;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  position: relative;
  margin-top: 25px;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero-content {
  position: relative;
  z-index: 2;
  padding: 20px;
}

.hero-content h1 {
  font-size: 30px;
  margin-bottom: 20px;
}

.hero-content p {
  font-size: 30px;
  margin-bottom: 20px;
}

.hero-text {
  margin-top: 350px;
  font-size: 12px;
  font-weight: lighter;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: -1px;
}

/* ====================================
// BLOG
// ==================================== */

.blog-all {
  margin-top: 45px;
}

.blog {
  margin: 70px 15px 50px 15px;
}

.container_02 {
  width: 100%; /* Utiliser toute la largeur de l'écran */
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centrer les éléments horizontalement */
  margin-top: 0px;
  padding: 0 0px;
}

.blog-post {
  width: calc(100% - 40px); /* Largeur totale moins les marges */
  background-color: #fff;
  margin: 30px 0 0 0;
  display: flex;
  flex-direction: row; /* Inverser l'ordre des éléments */
  transition: all 0.2s;
}

.blog-post:hover {
  transform: translateY(-3px);
}

.image-container {
  width: 50%;
  overflow: hidden;
}

.grid-container_02 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
}

.grid-video {
  width: 100%; /* Occupe toute la largeur */
  height: 400px; /* Définir la hauteur souhaitée */
  object-fit: cover; /* Couvre la zone sans déformer l'image (coupée si nécessaire) */
}

.grid-image {
  width: 100%; /* Occupe toute la largeur */
  height: 400px; /* Définir la hauteur souhaitée */
  object-fit: cover; /* Couvre la zone sans déformer l'image (coupée si nécessaire) */
}

.content-blog {
  flex: 1;
  box-sizing: border-box;
  margin-left: 30px;
}

.post-title {
  font-family: 'montserrat';
  font-weight: bold;
  position: relative;
  display: inline-block;
  margin-top: -40px;
  letter-spacing: -25px;
  font-style: italic;
  text-transform: uppercase;
  font-size: 13vw;
}

.post-title::after {
  content: '';
  position: absolute;
  left: 10px;
  bottom: 30px;
  width: 0;
  height: 7px;
  background-color: tomato;
  transition: width 0.3s ease-in-out;
}

.post-title:hover::after {
  width: 100%;
}

.down-post-title {
  margin: -10px 0 10px 0;
  background: tomato;
  color: white;
  font-weight: lighter;
  font-style: italic;
  border-radius: 30px;
  padding: 5px 13px 5px 10px;
  position: absolute;
}

.date {
  font-style: italic;
  color: #888;
  margin: 40px 0 0 0;
}

.post-content {
  font-style: italic;
  font-size: 27px;
  font-weight: lighter;
  letter-spacing: -1px;
  margin: 10px 0 0 0;
}

.more {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1000;
  text-decoration: underline;
  transition: 0.2s;
}

.more:hover {
  color: red;
  transition: 0.2s;
}

@media screen and (min-width: 2560px) {
  .grid-image {
    height: 560px;
  }
}

@media screen and (max-width: 425px) {
  .blog-post {
      width: 100%;
      flex-direction: column;
  }

  .post-title {
    font-size: 25vw;
    margin-top: 0;
    letter-spacing: -15px;
    margin: -20px 0 0;
    font-style: normal;
  }

  .date {
    color: #888;
    margin: 35px 0 0;
    font-style: italic;
}

  .down-post-title {
    margin: -5px 0 10px;
}

  .image-container,
  .content {
      width: 100%;
      height: auto;
  }
  .grid-container_02 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
}
.content-blog {
  padding-top: 20px;
  margin-left: 0;
}
}

/* ====================================
// FOOTER
// ==================================== */

footer {
  text-align: center;
  padding: 30px 20px;
  background-color: black;
  color: #fff;
  letter-spacing: -1px;
}

.footer-title {
  font-size: 100px;
  font-weight: bold;
  color: #777;
  margin: 0;
}

.footer-info {
  margin-top: 10px;
  font-size: 17px;
  line-height: 1.3;
  font-weight: 100;
}

.footer-info a {
  color: #fff;
  text-decoration: none;
}

.footer-info a:hover {
  text-decoration: underline;
}

.subscribe {
  margin-top: 30px;
  font-size: 16px;
  text-transform: uppercase;
}

.subscribe a {
  color: #fff;
  text-decoration: none;
}

.subscribe a:hover {
  text-decoration: 2px underline;
}

.copyright {
  font-size: 10px;
  color: gray;
  letter-spacing: normal;
}

/* ====================================
// ABOUT
// ==================================== */

.image-container-img {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  max-width: auto;
  padding-top: 50px;
}

.image-box img {
  width: 100%;
  height: auto;
  display: block; /* Evite les espaces sous l'image */
  object-fit: cover; /* S'assure que l'image couvre l'espace sans déformation */
}

/* Pour les écrans plus petits (mobiles) */
@media (max-width: 768px) {
  .image-container-img {
    grid-template-columns: repeat(2, 1fr);
  }
}


/* ====================================
// PROJECTS
// ==================================== */

/* Section principale */
.margin-top {
  margin-top: 100px;
}

/* Conteneur global */
.container {
  max-width: 100%;
  margin: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

/* Colonnes */
.col-md-6 {
  flex: 1 1 calc(50% - 10px);
  box-sizing: border-box;
}



a {
  text-decoration: none;
  color: inherit;
}

.content-column {
  position: relative;
  overflow: hidden;
  transition: transform 0.1s;
}

.content-column:hover {
  transform: scale(1);
}

/* Bloc bleu */
.blue {
  overflow: hidden;
  position: relative;
}

.conteneur {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* Ratio 16:9 */
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1; /* Afficher l'image par défaut */
  transition: opacity 0.2s, z-index 0s 0.2s; /* Transition fluide */
  z-index: 1; /* L'image est au-dessus par défaut */
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0; /* La vidéo est cachée par défaut */
  transition: opacity 0.2s, z-index 0s 0.2s; /* Transition fluide */
  z-index: 0; /* La vidéo est en dessous de l'image */
}

.click {
  margin: 0 0 20px 15px;
  font-weight: 400;
  margin-top: 200px;
  font-size: 12px;
  font-weight: lighter;
  text-transform: uppercase;
  letter-spacing: -1px;
}

/* Résponsive */
@media (max-width: 768px) {
  .col-md-6 {
    flex: 1 1 100%;
  }  
}

/* ====================================
// PROJ. FICHE TECHNIQUE
// ==================================== */

.container-02 {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Trois colonnes */
  gap: 20px;
  margin: 0 15px;
  background-color: #fff;
  border-radius: 8px;
}

/* Colonne Titre */
.title-column {
  text-align: left;
}

.big-title {
  font-family: 'montserrat';
  font-weight: bold;
  display: inline-block;
  letter-spacing: -5px;
  font-style: italic;
  text-transform: uppercase;
  font-size: 40px;
}

/* Colonne Détails */
.details-column {
  text-align: left;
}

.detail-item {
  margin: 5px 0;
  font-size: 0.9rem;
  color: #666;
}

.detail-partnership {
  background: tomato;
  color: white;
  font-weight: lighter;
  font-style: italic;
  border-radius: 30px;
  padding: 5px 13px 5px 10px;
  position: absolute;
  margin: 0 0 0 -10px;
}

.watch-now-button {
  display: inline-block;
  margin-top: 10px;
  text-decoration: none;
  font-size: 0.9rem;
  color: #007BFF;
  font-weight: bold;
}

.watch-now-button:hover {
  text-decoration: underline;
}

/* Colonne Contenu */
.content-column {
  text-align: left;
}

.content-paragraph {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 15px;
}

.content-link {
  color: #007BFF;
  text-decoration: none;
}

.content-link:hover {
  text-decoration: underline;
}

/* Responsive design */
@media (max-width: 768px) {
  .container-02 {
    grid-template-columns: 1fr; /* Une seule colonne */
  }

  .text-content-column {
    margin-top: 40px;
  }

  .title {
    font-size: 1.5rem;
  }

  .content-paragraph {
    font-size: 0.9rem;
  }
  
  .selection-container {
    flex-direction: column; /* Empile les sections verticalement */
    margin: 0; /* Retire les marges externes pour mieux s'adapter à l'écran */
  }

  .video-section,
  .images-section {
    width: 100%; /* Chaque section occupe toute la largeur */
    flex: unset; /* Supprime la répartition des largeurs */
  }

  .images-section img {
    height: auto; /* Ajuste la hauteur des images pour un affichage fluide */
  }
}

/* ====================================
// IMG + VID
// ==================================== */

.selection-container {
  display: flex;
  margin-top: 50px;
  max-width: auto;
  height: auto; /* Hauteur totale ajustée */
}

.video-section {
  flex: 2; /* La vidéo occupe 75% de la largeur */
  aspect-ratio: 16 / 9; /* Maintient le ratio 16:9 pour éviter les coupures */
  display: flex;
}

.video-section video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* La vidéo s'ajuste à l'espace sans déformation */
}

.images-section {
  flex: 1; /* Les images occupent 25% de la largeur */
  display: flex;
  flex-direction: column;
}

.images-section img {
  flex: 1;
  width: 100%;
  height: 50%; /* Chaque image prend la moitié de la section */
  object-fit: cover; /* Ajuste l'image sans la déformer */
}

.video-container {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: auto; /* Ajustez selon vos besoins */
}

.video-element {
  width: 100%;    /* Rend la vidéo responsive */
  height: auto;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 100%;
  font-size: 2rem;
  padding: 15px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Pour s'assurer que le bouton est au-dessus de la vidéo */
}

.play-button:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.play-button.hidden {
  display: none; /* Cache le bouton quand la vidéo est en lecture */
}

@media (max-width: 768px) {
  .play-button {
    display: none;
  }
}

/* ====================================
// CONTACT
// ==================================== */

  .contact-info {
    margin: 90px 15px 0 15px;
    color: black;
    letter-spacing: -1px;
    font-size: 20px;
    font-weight: 100;
  }

  .small-title {
    color: rgb(70, 70, 70);
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -1px;
  }

  .small-info {
    margin-bottom: 30px;
    letter-spacing: -1px;
  }

  .info-a {
    margin-bottom: 30px;
    letter-spacing: -1px;
  }

  .info-info {
    text-transform: normal;
    letter-spacing: -1px;
  }

  .info-a:hover {
    text-decoration: 2px underline;
  }

  .info-copyright {
    position: absolute;
    bottom: 10px;
    font-size: 10px;
    color: gray;
  }